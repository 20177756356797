import React from 'react';

function AdminHeader(props) {
    return (
        <>
            <h1>My Dashboard</h1>
        </>
    )
}

export default AdminHeader;